function navOpen(x) {
  if(x.matches) {
    document.getElementById("navigation").open = true;
  }
  else {
    document.getElementById("navigation").open = false;
  }
}

let x = window.matchMedia("(min-width: 768px)");

navOpen(x);
x.addListener(navOpen);